<div class="container py-2">
    <div class="h-100 border rounded-3 shadow">
        <div class="ratio ratio-16x9">
            <iframe
                style="border:0"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2396.8285857766964!2d17.802051732167268!3d53.077352500345675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4703732739ed86e1%3A0x1b7becf1b100e834!2zxYFvc29zaW93YSA5LCA4OS0yMDAgWmFtb8WbxIc!5e0!3m2!1spl!2spl!4v1726947365452!5m2!1spl!2spl"
            ></iframe>
        </div>
    </div>
</div>
