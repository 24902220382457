import {Component} from '@angular/core';

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrl: './info.component.scss'
})
export class InfoComponent {

}
