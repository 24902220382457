import {Component} from '@angular/core';

@Component({
    selector: 'app-regulations',
    templateUrl: './regulations.component.html',
    styleUrl: './regulations.component.scss'
})
export class RegulationsComponent {

}
