<div class="container py-2" id="about">
    <div class="h-100 p-5 bg-color text-white border rounded-3 text-center shadow">
        <img class="my-0 mr-md-auto logo logo-img" src="/assets/images/logo/derenda-logo-icon.svg"
             alt="DerendaApp Logo Icon" title="DerendaApp Logo Icon">
        <h1 class="mx-2 mx-md-0 display-6 fw-bold">Welcome Derenda App</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam assumenda hic, illum iusto omnis quidem
            quis velit. Commodi ratione recusandae sequi voluptatum. Aspernatur deleniti iure quibusdam veniam.
            Excepturi exercitationem expedita facere laudantium magnam nesciunt numquam officia optio porro, quas quod,
            repellat repudiandae sapiente sed unde veniam voluptatem? A, ad animi aperiam at autem beatae consequatur
            culpa dicta earum eos ipsa iusto libero nihil nobis nulla omnis quam qui reprehenderit suscipit vel vitae
            voluptatem voluptates voluptatibus. </p>
    </div>
</div>
