import {Component} from '@angular/core';

@Component({
    selector: 'app-opening-hours',
    templateUrl: './opening-hours.component.html',
    styleUrl: './opening-hours.component.scss'
})
export class OpeningHoursComponent {

}
