import {Component} from '@angular/core';
import {Activity} from "../../../shared/models/activity";

@Component({
    selector: 'app-activities',
    templateUrl: './activities.component.html',
    styleUrl: './activities.component.scss'
})
export class ActivitiesComponent {

    public activities: Activity[] = [
        {
            activityText: 'connecting and replacing hydrophores'
        },
        {
            activityText: 'ozonation'
        },
        {
            activityText: 'radiator service'
        },
        {
            activityText: 'unblocking pipes'
        },
        {
            activityText: 'installation, replacement, repair of gas installations, heating installations, water installations, sewage systems'
        },
        {
            activityText: 'construction of water and sewage installations'
        },
        {
            activityText: 'assembly and installation of sanitary equipment'
        },
        {
            activityText: 'installation of central heating installation'
        },
        {
            activityText: 'replacing radiator valves'
        },
        {
            activityText: 'venting heating installations'
        },
        {
            activityText: 'periodic inspections of sanitary installations'
        },
        {
            activityText: 'replacing pipes (plastic, copper and galvanized)'
        },
    ];
}
