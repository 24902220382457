import {Component, OnInit} from '@angular/core';
import {transition, trigger, useAnimation} from "@angular/animations";
import {fadeIn} from "../../../shared/animations/animations";

// models
import {Slider} from "../../../shared/models/slider";

@Component({
    selector: 'app-jumbotron',
    templateUrl: './jumbotron.component.html',
    styleUrl: './jumbotron.component.scss',
    animations: [
        trigger("fadeIn", [
            transition(":enter", [useAnimation(fadeIn, {params: {time: '900ms'}})]),
        ]),
    ],
})
export class JumbotronComponent implements OnInit {

    imageIndex = 0;

    public jumbotronImages: Slider[] = [
        {
            imgURL: '/assets/images/jumbotron/derenda-01.webp',
            slogan: 'radiator service'
        },
        {
            imgURL: '/assets/images/jumbotron/derenda-02.webp',
            slogan: 'unblocking pipes'
        },
        {
            imgURL: '/assets/images/jumbotron/derenda-03.webp',
            slogan: 'replacing valves'
        },
        {
            imgURL: '/assets/images/jumbotron/derenda-04.webp',
            slogan: 'leak removal'
        },
        {
            imgURL: '/assets/images/jumbotron/derenda-05.webp',
            slogan: 'gas furnace service'
        }
    ];

    ngOnInit(): void {
        this.changeImage();
    }

    changeImage(): void {
        setInterval((): void => {
            this.imageIndex += 1;
            if (this.imageIndex > 4) {
                this.imageIndex = 0;
            }
        }, 5000);
    }
}
