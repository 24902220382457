import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {MaterialModule} from "../shared/material/material.module";
import {SharedModule} from "../shared/shared.module";
import {FormsModule} from "@angular/forms";

// components
import {NavbarComponent} from './components/navbar/navbar.component';
import {ContactLinkComponent} from './components/navbar/contact-link/contact-link.component';
import {NavLinkComponent} from './components/navbar/nav-link/nav-link.component';
import {JumbotronComponent} from './components/jumbotron/jumbotron.component';
import {InfoComponent} from './components/info/info.component';
import {ActivitiesComponent} from './components/activities/activities.component';
import {ActivityComponent} from './components/activities/activity/activity.component';
import {ContactComponent} from './components/contact/contact.component';
import {ContactDetailsComponent} from './components/contact/contact-details/contact-details.component';
import {OpeningHoursComponent} from './components/contact/opening-hours/opening-hours.component';
import {MapComponent} from './components/map/map.component';
import {FooterComponent} from './components/footer/footer.component';

// public pages
import {PublicComponent} from './public.component';
import {HomeComponent} from './home/home.component';
import {RegulationsComponent} from './regulations/regulations.component';

// not found
import {NotFoundComponent} from './not-found/not-found.component';


const routes: Routes = [

    // public page
    {path: '', component: HomeComponent, pathMatch: 'full', title: "DerendaApp"},
    {path: 'regulations', component: RegulationsComponent, title: "Regulations"},

    // not found
    {path: '**', component: NotFoundComponent}

];

@NgModule({
    declarations: [
        PublicComponent,
        HomeComponent,
        NotFoundComponent,
        RegulationsComponent,
        NavbarComponent,
        ContactLinkComponent,
        NavLinkComponent,
        JumbotronComponent,
        InfoComponent,
        ActivitiesComponent,
        ActivityComponent,
        ContactComponent,
        ContactDetailsComponent,
        OpeningHoursComponent,
        MapComponent,
        FooterComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        MaterialModule,
        FormsModule,
        NgOptimizedImage,
        SharedModule
    ]
})
export class PublicModule {
}
