import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-activity',
    templateUrl: './activity.component.html',
    styleUrl: './activity.component.scss'
})
export class ActivityComponent {
    @Input() activityText = '';
}
